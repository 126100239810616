import React from "react";
import { Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  nwShowDataHero,
  nwShowDataWhiteGlove,
  nwShowDataLargeFeatureA,
  nwShowDataTrusted,
  nwShowDataMultiSection,
  nwShowDataBoothData,
} from "../../data/events/nw-food-data";
import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";

import heroBg from "../../images/hero-images/nightclub-bar-event-hero.png";
import CustomSlider from "../../components/EventsComps/CustomSlider";
import RotarySection from "../../components/RotarySection";
import BoothCTA from "../../components/EventsComps/BoothCTA";
import EventForm from "../../components/EventsComps/EventForm";
import { rotarySection } from "../../data/rotary-section-data";
import Heading from "../../components-v2/Base/HeadingBuilder";

const whiteGloveImport = "event-white-glove.png";
const { Title } = Typography;

const customHero = () => {
  return (
    <>
      <Heading
        level={1}
        injectionType={4}
        className="hero-b__title text-center"
      >
        Join SpotOn at the Northwest Food Show
        <span className="blue_dot">.</span>
        <br />
        <span
          style={{
            color: "#1769FF",
            fontSize: "38px",
            marginBottom: "24px",
            lineHeight: 1.5,
          }}
        >
          Portland | August 1 – 2, 2021
        </span>
        <p
          className="text-center"
          style={{
            color: "#748093 !important",
            fontSize: 20,
            marginTop: "24px",
            lineHeight: 1.5,
          }}
        >
          Join SpotOn at the Northwest Food Show in Portland at booth 210 to get
          an up-close look at our best-in-class point-of-sale and software tech
          built specifically for restaurants. Or click below to get a
          demonstration.
        </p>
      </Heading>
    </>
  );
};

const customType = {
  slides: [
    {
      bg: "kevin.png",
      cardTitle: "Kevin Bryla",
      cardContent:
        "Chief Marketing Officer & Head of Customer Experience for SpotOn",
      linkTo: "#",
    },
    // {
    //   bg: 'kevin.png',
    //   cardTitle: 'Hillary Holmes',
    //   cardContent: 'General Manager of Von Elrod’s Beer Hall & Kitchen',
    //   linkTo: '#',
    // },
  ],
};

const FoodShow = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="Northwest Food Show | SpotOn | Restaurant POS and softwar"
        description="Join SpotOn at the Northwest Food Show in Portland at booth 210 to get an up-close look at our best-in-class point-of-sale and software tech built specifically for restaurants."
        image={`https://spoton-website-staging.netlify.app/${heroBg}`}
      />
      <BHero
        sectionData={nwShowDataHero}
        heroBg="nightclub-bar-event-hero.png"
        clearBg
        customHeroContent={customHero()}
      />
      <div style={{ marginTop: "64px" }} />
      <WhiteGlove
        sectionData={nwShowDataWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <BoothCTA sectionData={nwShowDataBoothData} />
      <RotarySection sectionData={rotarySection} />
      <EventForm
        id="demo"
        leadType="tradeshow"
        formId="3802e416-0f35-43ee-936a-1b1cc7037886"
        thankYouPath="/events/thank-you"
      />
      {/*      <Articles
        sectionData={nwShowDataArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default FoodShow;
