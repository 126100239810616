// eslint-disable-next-line import/prefer-default-export
export const nwShowDataHero = {
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};

export const nwShowDataWhiteGlove = {
  mainTitle: "Powerful tech backed by 5-star service",
  title: "Because business is personal",
  content:
    "Running a restaurant is like doing a dozen jobs at once. We’re here to help. At SpotOn, we don’t just create innovative software and point-of-sale tools for restaurants, like Order with Google, our new commission-free online ordering tool. We also provide you with the in-person service and support you deserve to get the most from them. Come talk to us at the Northwest Food Show and see for yourself.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};
export const nwShowDataBoothData = {
  boothInfo: "Learn more at booth 210",
  boothComments:
    "Find SpotOn at booth 210 to learn more about the restaurant tech and personal support that can help keep you, your guests, and your employees happy.",
  boothImg: "nw-food-show.png",
  boothLocation: "Portland Expo Center Portland, Oregon",
  boothDirections: "2060 N. Marine Drive Portland, Oregon 97217",
  boothMap:
    "https://www.google.com/maps?f=q&source=s_q&hl=en&q=2060+N+Marine+Dr,+Portland,+Multnomah,+Oregon+97217&sll=37.0625,-95.677068&ss",
};

export const nwShowDataLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle:
        "How to Streamline Your Operations and Improve the Guest Experience",
      blockSubTitle:
        "Catch our educational session where you’ll get first hand insight into everything you need to know about streamlining your operations and improving your guest experience with technology.",
      blockList: [
        "Kevin Bryla, \nHead of Customer Experience for SpotOn",
        "Hillary Holmes, \nGM of Von Elrod’s Beer Hall & Kitchen",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/events/demo",
      },
    },
  ],
};

export const showRotarySection = {
  title: "SpotOn across the nation",
  subtext:
    "Our team is on the road to connect with small and midsize businesses operators and learn how we can better serve you.",

  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  // images: [],
  images: [
    {
      image: "1-image.png",
      className: "image-wrapper--1",
      thumbnail: "1-thumbnail.png",
    },
    {
      image: "2-image.png",
      className: "image-wrapper--2",
      thumbnail: "2-thumbnail.png",
    },
    {
      image: "3-image.png",
      className: "image-wrapper--3",
      thumbnail: "3-thumbnail.png",
    },
    {
      image: "4-image.png",
      className: "image-wrapper--4",
      thumbnail: "4-thumbnail.png",
    },
    {
      image: "5-image.png",
      className: "image-wrapper--5",
      thumbnail: "5-thumbnail.png",
    },
    {
      className: "image-wrapper--6",
      thumbnail: "6-thumbnail.png",
      location: "Activmeals",
    },
  ],
};
